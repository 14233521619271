<template>
  <div class="list-stocks">
    <KTCodePreview v-bind:title="'Danh sách phiếu kiểm tra tồn kho'">
      <template v-slot:toolbar>
        <div class="row">
          <div class="ml-3">
            <b-button variant="primary" size="sm" class="font-weight-bolder" id="show-btn" @click="showCreateModal">
              <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
            </b-button>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col>
            <b-row>
              <b-col>
                <date-picker placeholder="Từ ngày" class="form-control-sm" :config="dpConfigs.date"
                  v-model="dpForm.startDate"></date-picker>
              </b-col>
              <b-col>
                <date-picker placeholder="Đến ngày" class="form-control-sm" :config="dpConfigs.date"
                  v-model="dpForm.endDate"></date-picker>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-form-input v-model="searchProductStockCheckCode" type="text" placeholder="Mã phiếu kiểm tồn" size="sm"
              v-on:keyup.enter="onFilter()"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-form-select v-model="selectedStatus" :options="statusOptions" size="sm"></b-form-select>
          </b-col>
          <b-col >
            <MultiselectOption  :model.sync="valueStore" :options="filterdOptionsStore"
              :trackBy="'code'" @selectAll="onSelectStore($event)" @removeAll="onRemoveStore($event)"
              placeholder="cửa hàng" showNoResults @searchChange="onInputStoreChange($event)" />
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button style="fontweight: 600; width: 70px" variant="primary" size="sm" @click="onFilter">Lọc</b-button>
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <b-table :table-class="'table-centered'" :items="items" :fields="fields" head-row-variant="secondary" outlined>
          <template v-slot:cell(code)="data">
            <b-link id="inventory-check-modal" @click="editInventoryCheckItem(data.item)">
              {{ data.item.code }}
            </b-link>
          </template>
          <template v-slot:cell(status)="data">
            <StatusLabel :status="data.item.status" :statusName="data.item.statusName" />
          </template>
          <template v-slot:cell(action)="data">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i style="font-size: 1rem; padding-right: 0px" class="flaticon2-settings"></i>
                </template>
                <b-dropdown-item @click="editInventoryCheckItem(data.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Xem chi tiết
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteInventoryCheck(data.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem; color: #d33" class="flaticon2-rubbish-bin-delete-button"></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
          <template v-slot:cell(productType)="data">
            {{ productTypeName[data.item.productType] || 'Tất cả' }}
          </template>
          <template v-slot:cell(createdAt)="data">
            {{ formatDate(data.item.createdAt, 'DD-MM-YYYY') }}
          </template>
        </b-table>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số :
              {{ totalItem }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav class="custom-pagination" v-if="numberOfPage >= 2" :link-gen="linkGen"
              :number-of-pages="numberOfPage" use-router @change="fetchDataCheckSheets" align="right"
              first-class="page-item-first btn btn-icon btn-sm m-1" prev-class="page-item-prev btn btn-icon btn-sm m-1"
              next-class="page-item-next btn btn-icon btn-sm m-1 " last-class="page-item-last btn btn-icon btn-sm m-1 "
              page-class="btn btn-icon btn-sm border-0 m-1">
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <InventoryCheckAddModal v-on:fetchInventoryChecks="fetchDataCheckSheets()" ref="add-inventory-check-modal" />
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import InventoryCheckAddModal from '@/view/components/inventoryCheck/InventoryCheckAddModal.vue';
import moment from 'moment';
import StatusLabel from '@/view/components/inventoryCheck/StatusLabel.vue';
import { makeToastFaile } from '@/utils/common';
import { PRODUCT_STOCK_CHECK_STATUS } from '@/utils/enum';
import { formatDate } from '@/utils/common';
import { cloneDeep, map, find, assign } from 'lodash';
import MultiselectOption from '@/view/base/multiselect/MultiselectOption.vue';

export default {
  data() {
    return {
      PRODUCT_STOCK_CHECK_STATUS,
      page: 1,
      productTypeName: {
        1: 'Sản phẩm',
        2: 'Sản phẩm theo IMEI',
      },
      selectedCompany: null,
      checkSheets: [],
      searchProductStockCheckCode: '',
      dpForm: {
        startDate: '',
        endDate: '',
      },
      valueStore: [],
      filterdOptionsStore: [],
      optionsStore: [],
      totalItem: 0,
      numberOfPage: 0,
      selectedCheckSheetId: null,
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'code',
          label: 'Mã phiếu',
          thStyle: { width: '15%', fontSize: '1.2rem' },
          tdClass: 'font-weight-bold text-primary text-left  align-middle',
          thClass: 'text-center text-dark',
        },
        {
          key: 'storeName',
          label: 'Cửa hàng',
          thStyle: { width: '15%', fontSize: '1.2rem' },
          thClass: 'text-center text-dark',
          tdClass: 'text-left  align-middle',
        },
        {
          key: 'employeeName',
          label: 'Người tạo',
          thStyle: { width: '10%', fontSize: '1.2rem' },
          thClass: 'text-center text-dark',
          tdClass: 'text-left  align-middle',
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          thStyle: { width: '10%', fontSize: '1.2rem' },
          thClass: 'text-center text-dark',
          tdClass: 'text-right  align-middle ',
        },
        {
          key: 'productType',
          label: 'Loại sản phẩm',
          thStyle: { width: '15%', fontSize: '1.2rem' },
          thClass: 'text-center  text-dark',
          tdClass: 'text-center  align-middle ',
        },
        {
          key: 'status',
          label: 'Trạng thái',
          thStyle: { width: '10%', fontSize: '1.2rem' },
          thClass: 'text-center text-dark',
          tdClass: 'text-center align-middle',
        },
        {
          key: 'note',
          label: 'Ghi chú',
          thClass: 'text-center text-dark',
          tdClass: 'text-left align-middle',
          thStyle: { width: '15%', fontSize: '1.2rem' },
        },
        {
          key: 'action',
          label: '',
          tdClass: 'text-right align-middle',
          thStyle: { width: '10%', fontSize: '1.2rem' },
          thClass: 'text-center text-dark',
        },
      ],
      items: [],
      onLoading: false,
      selectedStatus: null,
      statusOptions: [
        { value: null, text: 'Chọn trạng thái' },
        { value: PRODUCT_STOCK_CHECK_STATUS.NEW, text: 'Mới' },
        { value: PRODUCT_STOCK_CHECK_STATUS.STOCK_DIFF, text: 'Tồn lệch' },
        { value: PRODUCT_STOCK_CHECK_STATUS.STOCK_DEBT, text: 'Truy thu' },
        { value: PRODUCT_STOCK_CHECK_STATUS.DONE, text: 'Hoàn thành' }
      ]
    };
  },
  methods: {
    formatDate,
    onSelectStore(option) {
      const index = this.optionsStore.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStore[index].checked = true;
      this.filterdOptionsStore = cloneDeep(this.optionsStore);
    },
    onRemoveStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name,
      );
      this.optionsStore[index].checked = false;
      this.filterdOptionsStore = cloneDeep(this.optionsStore);
    },
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          this.optionsStore = stores.map((element) => {
            return {
              id: element.id,
              name: element.shortName,
              checked: false,
            };
          });
          this.filterdOptionsStore = this.optionsStore;
          if (stores.length === 1) {
            this.optionsStore[0].checked = true;
          } 
          this.fetchDataCheckSheets();
        }
      });
    },
    fetchStore: async function () {
      this.optionsStore = [];
      ApiService.get(
        `/stores/getStores?companyId=${this.selectedCompany}`
      ).then((response) => {
        const stores = response.data.data;
        this.optionsStore = stores.map((element) => {
          return {
            id: element.id,
            name: element.shortName,
            checked: false,
          };
        });
        this.filterdOptionsStore = cloneDeep(this.optionsStore);
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-inventory-check',
      });
      this.fetchDataCheckSheets();
    },
    onInputStoreChange(textInput = '') {
      this.searchStore(textInput);
    },
    searchStore(textInput) {
      let options = cloneDeep(this.optionsStore);
      if (!textInput || !textInput.trim().length) {
        this.filterdOptionsStore = map(options, obj => {
          return assign(obj, find(this.filterdOptionsStore, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex(prop => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, obj => {
        return assign(obj, find(this.filterdOptionsStore, { id: obj.id }));
      });

      this.filterdOptionsStore = this.fitlerOptionsBy(options, textInput, 'name', 10);
    },
    editInventoryCheckItem(checkSheet) {
      this.$router.push({
        name: 'upsert-inventory-check',
        query: { id: checkSheet.id },
      });
    },
    showCreateModal() {
      this.$refs['add-inventory-check-modal'].showModal();
    },
    fetchDataCheckSheets: function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      const listStoreId = [];

      this.optionsStore.forEach((element) => {
        if (element.checked) {
          listStoreId.push(element.id);
        }
      });
      const params = {
        page: this.page,
        pageSize: 10,
        searchFromDay: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        searchToDay: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        status: this.selectedStatus,
        listStoreId: listStoreId,
        code: this.searchProductStockCheckCode.trim(),
      };
      ApiService.query('productStockCheck', {
        params,
      }).then(({ data }) => {
        this.totalItem = data.data.total_row
        this.numberOfPage = data.data.total_page;
        this.items = data.data.productCheckStocks;
      }).catch((error) => {
        if (error.response) {
          makeToastFaile(
            error.response.data ? error.response.data.message : 'Lỗi',
          );
        } else {
          makeToastFaile(error.message || 'Lỗi');
        }
      })
    },
    deleteInventoryCheck(inventoryCheck) {
      ApiService.delete(`productStockCheck/${inventoryCheck.id}`).then(() => {
        this.fetchDataCheckSheets();
      }).catch((error) => {
        if (error.response) {
          makeToastFaile(
            error.response.data ? error.response.data.message : 'Lỗi',
          );
        } else {
          makeToastFaile(error.message || 'Lỗi');
        }
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kiểm tra tồn kho' },
      { title: 'Danh sách phiếu kiểm tra tồn kho' },
    ]);
  },
  created() {
    this.fetchStoreByUser();
  },
  components: {
    KTCodePreview,
    InventoryCheckAddModal,
    StatusLabel,
    MultiselectOption,
  },
};
</script>

<style scoped>
.check-sheet-list {
  max-height: 600px !important;
  overflow-y: auto !important;
}

.table-centered td {
  vertical-align: middle;
}
</style>
